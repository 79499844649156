<template>
    <div class="d-flex animate">
        <div v-for="(b, index) in breadcrumbs" :key="b.url">
            <router-link :to="b.url">{{ b.title }}</router-link>
            <span v-if="index < breadcrumbs.length - 1" class="text-white mx-2">></span>
        </div>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'breadcrumbs-component',
    props: ['breadcrumbs'],
}
</script>

<style scoped>
@keyframes appear {
    0% {
        opacity: 0;
        transform: translateX(-8px);
    }

    100% {
        opacity: 1;
        transform: 0;
    }
}

.animate {
    opacity: 0;
    animation: appear 500ms ease forwards;
    animation-delay: 200ms;
}
</style>