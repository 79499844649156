<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="p-lg-4 py-4 ms-lg-4 left">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />
            <h1 class="mt-4">Contact</h1>
            <div class="row row-cols-1 row-cols-lg-2">
                <div class="col">
                    <p class="paragraph-text text-white">Neem gerust met gebruik van dit contactformulier contact met mij
                        op.
                    </p>
                    <hr class="mb-4"/>
                    <div class="row row-cols-1 row-cols-lg-2 my-3">
                        <div class="col col-lg-3">
                            <h5 class="text-white">Naam<span class="text-warning">*</span></h5>
                        </div>
                        <div class="col">
                            <input type="text" required class="form-control rounded-1" placeholder="Je naam">
                        </div>
                    </div>

                    <div class="row row-cols-1 row-cols-lg-2 my-3">
                        <div class="col col-lg-3">
                            <h5 class="text-white">E-mailadres<span class="text-warning">*</span></h5>
                        </div>
                        <div class="col">
                            <input type="email" required class="form-control rounded-1" placeholder="Je e-mailadres">
                        </div>
                    </div>

                    <div class="row row-cols-1 row-cols-lg-2 my-3">
                        <div class="col col-lg-3">
                            <h5 class="text-white">Telefoonnummer</h5>
                        </div>
                        <div class="col">
                            <input type="tel" class="form-control rounded-1"
                                placeholder="Je telefoonnummer (niet verplicht)">
                        </div>
                    </div>

                    <div class="row row-cols-1 my-3">
                        <div class="col-12">
                            <h5 class="text-white">Bericht<span class="text-warning">*</span></h5>
                        </div>
                        <div class="col-12 col-lg-9">
                            <textarea rows="5" required class="form-control rounded-1" placeholder="Je bericht"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <a href="#" class="btn btn-primary btn-gold border-0 rounded-1"><i
                                    class="fa fa-envelope me-1"></i>
                                Verzenden</a>
                        </div>
                    </div>
                </div>

                <hr class="d-block d-lg-none"/>

                <div class="col-12 col-md-4 mt-4 mt-lg-0 text-white paragraph-text">
                    <h2 class="border-0">Contactgegevens</h2>
                    <div class="row row-cols-1 row-cols-lg-2 mb-3">
                        <div class="col fw-bold">
                            Adres
                        </div>
                        <div class="col">
                            't Spiker 103
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-lg-2 mb-3">
                        <div class="col fw-bold">
                            Telefoon
                        </div>
                        <div class="col">
                            06 16 80 06 69
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-lg-2 mb-3">
                        <div class="col fw-bold">
                            E-mail
                        </div>
                        <div class="col">
                            <a href="mailto:franckenella@gmail.com">franckenella@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
    </div>
</template>

<script type="text/javascript">
import FlexBanner from '@/components/FlexBannerComponent.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';

export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [{
                url: "/",
                title: "Home",
            }, {
                url: "/contact",
                title: "Contact",
            }]
        }
    }
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

.fitment {
    max-height: 512px;
    object-fit: cover;
    object-position: 0 40%;
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}</style>