<template>
    <div :class="['position-fixed overlay p-3 text-center max-size-modal', openModal ? 'pe-all' : 'pe-none hidden']"
        id="overlay">
        <div
            :class="['modal-image mt-4 mx-auto text-white text-start rounded-0 position-relative', openModal ? '' : 'hide']">
            <i class="fa fa-times position-absolute" @click="closeModal"></i>
            <div class="p-4">
                <h2 class="border-0 h1 display-5 m-0">{{ title }}</h2>
            </div>
            <hr class="m-0" />

            <!-- Nieuw beeld -->
            <template v-if="purpose === 'add_sculpture'">
                <form method="POST" action="https://vorm-vrij.nl/scripting/add_sculpture.php" enctype="multipart/form-data">
                    <div class="p-4">
                        <div class="form-group mb-3">
                            <h4>Titel</h4>
                            <input type="text" placeholder="Titel beeld" class="form-control rounded-1" name="name">
                        </div>

                        <div class="form-group mb-3">
                            <h4>Categorie</h4>
                            <select class="form-control" name="category_id">
                                <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
                            </select>
                        </div>

                        <div class="form-group mb-3">
                            <h4>Beschrijving</h4>
                            <textarea class="form-control rounded-1" name="description" placeholder="Zet hier een beschrijving neer..."
                                rows="4" :value="description"></textarea>
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="is_cursistenwerk">
                                <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Beeld is
                                    cursistenwerk</label>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="visible" checked>
                                <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Zichtbaar op
                                    website</label>
                            </div>
                        </div>

                        <div class="row row-cols-1 row-cols-lg-4 mb-3">
                            <div class="col col-lg-3 btn-text pt-lg-2">
                                Breedte (cm)
                            </div>
                            <div class="col mb-2 mb-lg-0">
                                <input type="number" class="form-control rounded-1" placeholder="0" name="width">
                            </div>
                            <div class="col col-lg-3 btn-text pt-lg-2">Hoogte (cm)</div>
                            <div class="col">
                                <input type="number" class="form-control rounded-1" placeholder="0" name="height">
                            </div>
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <h4>Foto</h4>
                            <input type="file" accept="image/*;capture=camera" name="file_upload">
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <button class="btn btn-gold fw-bold"><i class="fa fa-plus me-1"></i> Toevoegen!</button>
                        </div>
                    </div>
                </form>
            </template>

            <!-- Bestaande bewerken -->
            <template v-if="purpose === 'edit_sculpture'">
                <form method="POST" action="https://vorm-vrij.nl/scripting/edit_sculpture.php">
                    <input type="hidden" class="d-block" name="id" :value="id">
                    <input type="hidden" class="d-block" name="direct_image_url" :value="direct_image_url">
                    <div class="p-4">
                        <div class="form-group mb-3">
                            <h4>Titel</h4>
                            <input type="text" placeholder="Titel beeld" class="form-control rounded-1" name="name"
                                :value="title">
                        </div>

                        <div class="form-group mb-3">
                            <h4>Categorie</h4>
                            <select class="form-control" name="category_id" :value="category_id">
                                <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
                            </select>
                        </div>

                        <div class="form-group mb-3">
                            <h4>Beschrijving</h4>
                            <textarea class="form-control rounded-1" name="description" placeholder="Zet hier een beschrijving neer..."
                                rows="4" :value="description"></textarea>
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="is_cursistenwerk" :checked="is_cursistenwerk === 1" id="flexSwitchCheckDefault">
                                <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Beeld is
                                    cursistenwerk</label>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" name="visible" :checked="visible === 1" id="flexSwitchCheckDefault">
                                <label class="form-check-label btn-text" for="flexSwitchCheckDefault">Zichtbaar op
                                    website</label>
                            </div>
                        </div>

                        <div class="row row-cols-1 row-cols-lg-4 mb-3">
                            <div class="col col-lg-3 btn-text pt-lg-2">
                                Breedte (cm)
                            </div>
                            <div class="col mb-2 mb-lg-0">
                                <input type="number" placeholder="0" name="width" class="form-control rounded-1" :value="width">
                            </div>
                            <div class="col col-lg-3 btn-text pt-lg-2">Hoogte (cm)</div>
                            <div class="col">
                                <input type="number" placeholder="0" name="height" class="form-control rounded-1" :value="height">
                            </div>
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <h4>Foto</h4>
                            <input type="file" name="file_upload" accept="image/*;capture=camera">
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <button type="submit" class="btn btn-gold fw-bold"><i class="fa fa-pencil me-1"></i> Aanpassen</button>
                            <div class="btn btn-danger fw-bold float-end" @click="() => deleteSculpture(id)"><i class="fa fa-trash me-1"></i> Verwijderen</div>
                        </div>
                    </div>
                </form>
            </template>

            <!-- Nieuw nieuwsartikel plaatsen -->
            <template v-if="purpose === 'news'">
                <form method="POST" action="https://vorm-vrij.nl/scripting/add_news.php" enctype="multipart/form-data">
                    <div class="p-4">
                        <div class="form-group mb-3">
                            <h4>Titel</h4>
                            <input type="text" placeholder="Titel artikel" name="title" class="form-control rounded-1">
                        </div>
                        <div class="form-group mb-3">
                            <h4>Inhoud</h4>
                            <textarea class="form-control rounded-1" name="content" placeholder="Zet hier de inhoud van het artikel neer..."
                                rows="4"></textarea>
                        </div>
                        <div class="form-group mb-3">
                            <h4>Foto</h4>
                            <input type="file" accept="image/*;capture=camera" name="file_upload">
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <button type="submit" class="btn btn-gold fw-bold"><i class="fa fa-plus me-1"></i> Toevoegen!</button>
                        </div>
                    </div>
                </form>
            </template>

            <!-- Nieuws bewerken -->
            <template v-if="purpose === 'edit_news'">
                <form method="POST" action="https://vorm-vrij.nl/scripting/edit_news.php" enctype="multipart/form-data">
                    <input type="hidden" class="d-block" name="id" :value="id">
                    <input type="hidden" class="d-block" name="publication_date" :value="publication_date">
                    <input type="hidden" class="d-block" name="pictures" :value="pictures">
                    <div class="p-4">
                        <div class="form-group mb-3">
                            <h4>{{ title }}</h4>
                            <input type="text" placeholder="Titel artikel" name="title" :value="title" class="form-control rounded-1">
                        </div>
                        <div class="form-group mb-3">
                            <h4>Inhoud</h4>
                            <textarea class="form-control rounded-1" name="content" :value="content" placeholder="Zet hier de inhoud van het artikel neer..."
                                rows="4"></textarea>
                        </div>
                        <div class="form-group mb-3">
                            <h4>Foto</h4>
                            <input type="file" accept="image/*;capture=camera" name="file_upload">
                        </div>

                        <div class="hr-line-dashed"></div>

                        <div class="form-group mb-3">
                            <button type="submit" class="btn btn-gold fw-bold"><i class="fa fa-pencil me-1"></i> Aanpassen</button>
                        </div>
                    </div>
                </form>
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';

export default {
    name: 'image-modal',
    props: ['title', 'purpose', 'description', 'direct_image_url', 'width', 'height', 'openModal', 'category_id', 'is_cursistenwerk', 'id', 'content', 'publication_date', 'pictures', 'visible'],
    data() {
        return {
            formAction: "https://vorm-vrij.nl/scripting/add_sculpture.php",
            categories: [],
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        async addSculpture() {
            await fetch('https://vorm-vrij.nl/scripting/add_sculpture.php').then((response) => {
                return response.json();
            }).then((res) => {
                console.log(res);
            });
        },
        async deleteSculpture(id) {
            await fetch(`https://vorm-vrij.nl/scripting/delete_sculpture.php?id=${id}`).then(() => {
                location.reload();
            }).then((res) => {
                console.log(res);
            });
        }
    },
    async mounted() {
        $("#overlay").click((e) => {
            if ($(e.target).attr('id') === 'overlay')
                this.$emit('closeModal');
        });

        await fetch('https://vorm-vrij.nl/scripting/get_categories.php').then((response) => {
            return response.json();
        }).then((res) => {
            this.categories = res;
        }).catch((err) => {
            console.log(err);
        });
        
        switch(this.purpose) {
            case "add_sculpture":
                this.formAction = "https://vorm-vrij.nl/scripting/add_sculpture.php";
                break;
        }
    }
}
</script>

<style scoped>
.overlay {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1500;
    transition: background-color 500ms ease;
}

.hide {
    opacity: 0;
}

.max-size-modal {
    max-height: calc(100vh);
    overflow-y: scroll;
}

.fa-times {
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
    transition: transform 400ms ease;
}

.fa-times:hover {
    transform: rotateZ(90deg) scale(125%);
}

.max-size {
    max-height: 62vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.max-size::-webkit-scrollbar {
    width: 0;
}

.hidden {
    background-color: transparent;
}

.modal-image {
    width: 100%;
    max-width: 640px;
    background-color: var(--bg-nella);
    box-shadow: 1px 2px 39px #000;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: opacity 400ms ease;
}</style>