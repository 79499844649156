<template>
    <FlexBanner :image="'banner-houses'" :backdrop="'banner-gray'" />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <img src="@/assets/beelden/fruit_bokeh.png" class="one-image">
        <div class="px-lg-4 py-4 mx-lg-4 leftr">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />
            <h1 class="mt-4">Cursistenwerk</h1>
            <p class="text-white paragraph-text">
                Ik geef boetseerles aan kleine groepjes van maximaal 3 personen. Dat doe ik in mijn eigen huis. Daarvoor heb
                ik een kamer als atelier ingericht. De meeste mensen die beginnen hebben nog nooit geboetseerd. Dat is geen
                enkel probleem.
            </p>
            <p class="text-white paragraph-text">
                In de eerste twee lessen leer je hoe je beelden kunt opbouwen. Daarna mag je aangeven wat je wilt maken,
                mits het niet te ingewikkeld is. In een paar lessen kun je al mooie dingen maken. Hierna moet het drogen en
                daarna mag je het beschilderen met keramische verven. Dan wordt het gebakken in de keramische oven op 1000
                tot 1040 graden. Wil je het glazuren, dan moet het nogmaals gebakken worden. Tussendoor drinken we koffie of
                thee. Het is een heel gemoedelijk gebeuren.
            </p>
            <div class="mb-5"></div>
            <h2>Werk van cursisten</h2>
            <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                <div class="col" v-for="item in cursistenItems" :key="item.id">
                    <img :src="item.direct_image_url !== 'na' ? `upload/foto/${item.direct_image_url}` : 'upload/foto/nog_geen.jpg'" class="w-100 image border-subtle" :alt="item.name" :title="item.name" @click="() => expandPhoto(item)">
                    <div class="p-0 py-2 py-sm-3 p-sm-3">
                        <p class="text-white fw-bold m-0">{{ item.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ImageModal :title="currentItem?.name" :description="currentItem?.description" :width="currentItem?.width" :height="currentItem?.height" :direct_image_url="currentItem?.direct_image_url" :openModal="openModal" @closeModal="closeModal" />
</template>

<script type="text/javascript">
import FlexBanner from "@/components/FlexBannerComponent.vue";
import Breadcrumbs from "@/components/BreadcrumbsComponent.vue";
import ImageModal from "@/components/ImageModalComponent.vue";

export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Breadcrumbs,
        ImageModal,
    },
    data() {
        return {
            breadcrumbs: [{
                url: "/",
                title: "Home",
            }, {
                url: "/cursistenwerk",
                title: "Cursistenwerk",
            }],
            cursistenItems: [],
            currentItem: undefined,
            openModal: false,
        }
    },
    methods: {
        expandPhoto(item) {
            this.currentItem = item;
            this.openModal = true;
        },
        closeModal() {
            this.openModal = false;
        }
    },
    async mounted() {
        await fetch('https://vorm-vrij.nl/scripting/get_cursistenwerk.php').then((response) => {
            return response.json();
        }).then((res) => {
            this.cursistenItems = res;
        }).catch((err) => {
            console.log(err);
        })
    }
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

.image {
    transition: transform 300ms ease-in-out;
    cursor: pointer;
    animation: appear 900ms forwards;
}
.image:hover {
    transform: scale(105%);
}

@keyframes appear {
    0% {
        opacity: 0;
    }    

    100% {
        opacity: 1;
    }
}

.border-subtle {
    border: 1px solid rgba(255, 255, 255, 0.15);
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}
</style>