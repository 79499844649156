<template>
    <div class="container">
        <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4">
            <div class="col" v-for="image in images" :key="image.id">
                <img :src="image.url" :alt="image.name">
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'collage-component',
    data() {
        return {
            images: [],
        }
    }
}
</script>

<style scoped>
</style>