<template>
    <div class="position-relative max-1920">
        <div class="w-100 text-center overflow-hidden text-lg-start px-4 text-white bg-nella hght-300 d-none d-lg-block text-start position-relative" id="topBanner">
            <router-link to="/" class="no-border my-auto">
                <img src="@/assets/logo_nella.png" class="logo h-100" role="button">
            </router-link>
        </div>
        <div class="d-none d-lg-flex w-100 nav-bar">
            <router-link v-for="link in links" :key="link.to" :to="link.to" 
                :class="['button text-white', currentRouteName === link.to ? 'selected' : '']">
                <p class="my-3 ">{{ link.text }}</p>
                <div :class="['btn-gold m-0 h-1', currentRouteName === link.to ? 'w-100' : '']"></div>
            </router-link>
        </div>
    </div>

    <div @click="closeHamburger" :class="['banner-overlay', openHamburger ? 'opened' : '']">
        <div :class="['side-menu bg-dark pt-1 max-size-burger', openHamburger ? 'into-view' : '']">
            <router-link v-for="link in links" :key="link.to" :to="link.to" 
                :class="['button text-white text-start side-item', currentRouteName === link.to ? 'selected' : '']">
                <p class="my-3 ms-4">{{ link.text }}</p>
                <div :class="['btn-gold m-0 h-1', currentRouteName === link.to ? 'w-100' : '']"></div>
            </router-link>
        </div>
    </div>

    <div class="w-100 hght-100 position-fixed d-block d-lg-none bg-nella">
        <img src="@/assets/logo_nella.png" class="text-center h-100 logo-scale">
    </div>
    <i :class="['fa icon d-block d-lg-none my-auto on-top', openHamburger ? 'fa-times' : 'fa-bars']" @click="toggleHamburger"></i>
</template>

<script type="text/javascript">
window.addEventListener("scroll", function() {
    let topBanner = document.getElementById("topBanner");
    let scrollY = window.scrollY;
    topBanner.style.maxHeight = `${Math.max(195 - scrollY, 75)}px`;
});

export default {
    name: 'banner-component',
    data() {
        return {
            links: [
                {
                    to: '/',
                    text: 'Home',
                },
                {
                    to: '/over-mij',
                    text: 'Over mij',
                },
                {
                    to: '/eigen-werk',
                    text: 'Eigen werk',
                },
                {
                    to: '/werkwijze',
                    text: 'Werkwijze',
                },
                {
                    to: '/cursistenwerk',
                    text: 'Cursistenwerk',
                },
                {
                    to: '/nieuws',
                    text: 'Nieuws',
                },
                {
                    to: '/gastenboek',
                    text: 'Gastenboek',
                },
                {
                    to: '/contact',
                    text: 'Contact',
                }
            ],
            openHamburger: false,
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.fullPath;
        }
    },
    methods: {
        closeHamburger() {
            this.openHamburger = false;
        },
        toggleHamburger() {
            this.openHamburger = !this.openHamburger;
        }
    },
    mounted() {
        let topBanner = document.getElementById("topBanner");
        let scrollY = window.scrollY;
        topBanner.style.maxHeight = `${Math.max(195 - scrollY, 95)}px`;
    }
}
</script>

<style scoped>
.bg-nella {
    background-image: url("@/assets/web/bannner_bg.png");
    background-size: cover;
    z-index: 700;
}

.max-size-burger {
    max-height: 100vh;
    overflow-y: scroll;
}

.side-menu {
    width: 90%;
    max-width: 400px;
    margin-top: 75px;
    height: calc(100vh - 75px);
    transform: translateX(-100%);
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    opacity: 0;
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
}

.side-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.on-top {
    z-index: 1024;
    position: relative;
}

.into-view {
    transform: translateX(0);
    opacity: 1;
}

.opened {
    background-color: rgba(0, 0, 0, 0.75) !important;
    pointer-events: all !important;
}

.banner-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background-color: transparent;
    transition: background-color 400ms ease-in-out;
    z-index: 512;
}

.h-1 {
    height: 2px;
    transform: translateY(3px);
    width: 0;
    transition: width 400ms ease;
}

.nav-bar {
    background-color: var(--bg-nav);
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo-scale {
    transform: scale(0.75);
}

.my-3 {
    transform: translateY(3px);
}

.button:hover > .h-1 {
    width: 100%;
}

.selected {
    background-color: rgba(179, 153, 77, 0.08);
}

.hght-300 {
    height: 190px;
}

.hght-100 {
    height: 75px;
}

.icon {
    font-size: 1.5em;
    position: absolute;
    left: 1em;
    top: calc(37.5px - 0.5em);
    opacity: 1;
    color: rgba(255, 255, 255, 0.8);
}

.button {
    width: 200px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    font-family: "Raleway", Arial;
}

.logo {
    max-width: 512px;
    border: none !important;
    transform: scale(80%);
}

.no-border {
    border: none !important;
}

.button:hover {
    background-color: var(--bg-nav-hover);
}
</style>