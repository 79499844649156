<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <img src="@/assets/beelden/fruit_bokeh.png" class="one-image">
        <div class="px-lg-4 py-4 mx-lg-4 leftr">
            <h1>Welkom</h1>
            <p class="text-white paragraph-text">
                Leuk dat je mijn website Vorm-vrij bezoekt. Ik ben benieuwd wat je ervan vindt. Misschien heb je nog wat opmerkingen daarover. Fijn als je dat laat weten via <a href="mailto:franckenella@gmail.com">franckenella@gmail.com</a> of via het <router-link to="/gastenboek">Gastenboek</router-link>.
            </p>
            <p class="text-white paragraph-text">
                Blader maar verder en geniet. Ik hoop dat je enthousiast wordt van mijn keramiek.
            </p>
            <p class="text-white paragraph-text">
                <u>Alle werken zijn te koop, prijs op aanvraag.</u>
            </p>
            <p class="text-white paragraph-text">
                Groetjes, Nella
            </p>
            <hr>
            <p class="text-white paragraph-text">
                Nog meer weten? <a href="/files/CV Nella Francke 2022.pdf" download>Download hier mijn CV!</a>
            </p>
            <h2 class="mt-3">Mijn werk</h2>
            <div class="text-white paragraph-text">
                <router-link to="/eigen-werk" class="paragraph-text">Bezoek gerust de <strong>Eigen werk pagina</strong></router-link>&nbsp;om te zien waar ik allemaal aan heb gewerkt.
            </div>
        </div>
    </div>
    <img src="@/assets/beelden/fruit.jpeg" class="fruit-banner w-100" alt="Fruit">
    <Preview/>
</template>

<script type="text/javascript">
import FlexBanner from "@/components/FlexBannerComponent.vue";
import Preview from "@/components/PreviewComponent.vue";

export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Preview,
    }
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}
</style>