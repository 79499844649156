import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import OverMijPage from '../pages/OverMijPage.vue'
import WerkwijzePage from '../pages/WerkwijzePage.vue'
import NieuwsPage from '../pages/NieuwsPage.vue'
import EigenWerkPage from '../pages/EigenWerkPage.vue'
import CursistenwerkPage from '../pages/CursistenwerkPage.vue'
import GastenboekPage from '../pages/GastenboekPage.vue'
import ContactPage from '../pages/ContactPage.vue'
import DashboardPage from '../pages/admin/DashboardPage.vue'
import BeeldBeheerPage from '../pages/admin/BeeldBeheerPage.vue'
import CategoriePage from '../pages/admin/CategoriePage.vue'
import AdminLoginPage from '../pages/admin/AdminLoginPage.vue'
import NieuwsBeheerPage from '../pages/admin/NieuwsBeheerPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/over-mij',
    name: 'over-mij',
    component: OverMijPage,
  },
  {
    path: '/werkwijze',
    name: 'werkwijze',
    component: WerkwijzePage,
  },
  {
    path: '/nieuws',
    name: 'nieuws',
    component: NieuwsPage,
  },
  {
    path: '/eigen-werk',
    name: 'eigen-werk',
    component: EigenWerkPage,
  },
  {
    path: '/cursistenwerk',
    name: 'cursistenwerk',
    component: CursistenwerkPage,
  },
  {
    path: '/gastenboek',
    name: 'gastenboek',
    component: GastenboekPage,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
  },
  {
    path: '/admin',
    name: 'admin',
    component: DashboardPage,
  }, {
    path: '/admin/beeld-beheer',
    name: 'beeld-beheer',
    component: BeeldBeheerPage,
  }, {
    path: '/admin/categorieen',
    name: 'categorieen',
    component: CategoriePage,
  }, {
    path: '/admin/nieuws',
    name: 'nieuws-beheer',
    component: NieuwsBeheerPage,
  }, {
    path: '/admin/login',
    name: 'login',
    component: AdminLoginPage,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if(!to.fullPath.includes('admin'))
      return { x: 0, y: 0, top: 0, behavior: 'smooth' };
    return {behavior: 'smooth'};
  }
})

router.beforeEach((to) => {
  let title = "";
  switch(to.name) {
    case "home":
      title = "Vorm-vrij | Home";
      break;
    default:
      title = "Vorm-vrij | Home";
      break;
  }
  document.title = title;
});

export default router
