<template>
  <div class="banner-sticky w-100">
    <Banner/>
  </div>

  <div class="app_main mt-lg-0">
    <router-view/>
  </div>
  <Footer/>
</template>

<script type="text/javascript">
import Banner from '@/components/BannerComponent.vue';
import Footer from '@/components/FooterComponent.vue';
export default {
  name: 'app-component',
  components: {
    Banner,
    Footer
  }
}
</script>

<style>
:root {
  --bg-nella: #141d29;
  --bg-nav: #121218;
  --bg-nav-hover: #303039;
  --bg-paragraph: #0f0f14;

  --nella-gold: rgb(179, 153, 77);
}

.text-gold {
  color: var(--nella-gold);
}

.app_main {
  margin-top: 220px !important;
}

html, body {
  overflow-x: clip;
  background-color: var(--bg-paragraph);
}

.btn-gold {
  background-color: var(--nella-gold) !important;
}

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1920px;
  margin: auto;
}

.paragraph-text {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.5px;
  animation: fade-in 800ms ease forwards;
  animation-delay: 300ms;
  opacity: 0;
}

.btn-text {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.hr-line-dashed {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

h1, h2 {
  color: var(--nella-gold);
  font-family: "Raleway", Arial, sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0.5em;
  animation: fade-in 800ms ease;
}

h1 {
  color: rgb(224, 197, 121);
  text-shadow: 0 0 12px rgba(179, 153, 77);
}

h3, h4, h5 {
  animation: fade-in 800ms ease;
}

.img {
  animation: fade-in 800ms ease forwards;
  opacity: 0;
  animation-delay: 500ms;
}

h3 {
  color: #fff;
  font-family: "Raleway", Arial;
  font-size: 32pt;
  font-weight: bold;
  font-style: italic;
}

.max-1920 {
  max-width: 1920px;
}

a {
  color: var(--nella-gold);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms, color 200ms;
}

a:hover {
  color: red;
  border-bottom: 1px solid red;
}

.banner-sticky {
  position: fixed;
  top: 0;
  z-index: 1024;
}

.w-md-75 {
  width: 75%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 991px) {
  .banner-sticky {
    position: fixed;
    top: 0;
    z-index: 1024;
  }

  .w-md-75 {
    width: 100% !important;
  }

  .app_main {
    margin-top: 64px !important;
  }
}

input, textarea {
  opacity: 0;
  animation-delay: 800ms;
  animation: fade-in 600ms ease forwards;
}
</style>
