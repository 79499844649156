<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="row row-cols-1 m-0 row-cols-lg-2">
            <div class="p-0 m-0 px-lg-4 py-4 mx-lg-4 leftr col-12 col-lg-8">
                <Breadcrumbs :breadcrumbs="breadcrumbs"/>
                <h1 class="mt-4">Mijn werkwijze</h1>
                <p class="paragraph-text text-white">Ik zorg dat ik lekkere, soepele klei heb. Wat ik ga maken: heb ik globaal in mijn hoofd. Al bouwende vormt zich een idee, maar dit kan heel wat anders zijn dan ik van plan was. Ik bouw met kleine stukjes klei, die ik als dakpannetjes boven en aan de zijkanten van de klei vastklei. Het grootste probleem zijn luchtbellen in de klei, dus daar let ik goed op. Het zou erg jammer zijn als je mooie product in de oven ontploft.
                </p>
                <hr/>
                <p class="paragraph-text text-white">Met mesjes, houtjes en een slaghout wordt alles in de vorm gebracht die ik voor ogen heb. Nu moet het werkstuk drogen. Dat duurt ongeveer een week, omdat ik alles hol opbouw. Ook moet er aan de onderkant een klein gaatje zitten, waar de lucht tijdens het bakken kan ontsnappen. Zit er geen gaatje, dan ontploft het werkstuk. Is het werk goed droog, dan kleur ik het met keramische kleuren en metaaloxydes.
                </p>
                <hr/>
                <p class="paragraph-text text-white">De volgende stap is het bakken. Eerst begin ik met droogstoken op 150 graden, zodat ik zeker weet dat alles goed droog is. Dat gebeurt in een elektrische oven. Deze wordt daarna langzaam opgestookt tot 600 graden en daarna gaat het sneller tot 1000 of 1050 graden, afhankelijk of ik ga glazuren of niet. Daarna moet de oven afkoelen. Dat duurt ongeveer een hele dag. Heel lang, want je wilt de inhoud zo graag zien! <b>Geduld is belangrijk hierbij.</b></p>
                <hr />
            </div>
            <div class="px-lg-4 py-4 mx-lg-4 leftr col-12 col-lg-3">
                <img src="@/assets/beelden/muis.jpeg" class="w-100 float-end text-end img">
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import FlexBanner from '@/components/FlexBannerComponent.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [{
                url: "/",
                title: "Home",
            }, {
                url: "/werkwijze",
                title: "Werkwijze",
            }]
        }
    }
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}</style>