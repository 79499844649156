<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="row m-0 p-0 row-cols-1 m-0 row-cols-lg-2">
            <div class="p-0 m-0 px-lg-4 py-4 mx-lg-4 leftr col-12 col-lg-8">
                <Breadcrumbs :breadcrumbs="breadcrumbs"/>
                <h1 class="mt-4">Over mij</h1>
                <p class="paragraph-text text-white">Op de kweekschool heb ik veel plezier gehad van de lessen
                    handvaardigheid.
                </p>
                <p class="paragraph-text text-white">Toen ik voor de klas stond, heb ik met de leerlingen heel wat
                    afgeknutseld.
                </p>
                <p class="paragraph-text text-white">Begin tachtiger jaren werd ik heel enthousiast over klei. Ik volgde een
                    paar korte cursussen, daarna ging ik zelfstandig verder. Ik ben begonnen met schalen, vazen en sieraden,
                    maar al snel kwamen er andere objecten bij. Steeds verder probeerde ik mezelf te ontwikkelen.
                    Ondertussen
                    was ik vaak op kunstmarkten en exposities te vinden. Dat heb ik veel gedaan.</p>

                <hr />

                <p class="paragraph-text text-white">Fysiek lukt dat nu niet meer en daarom ben ik begonnen met
                    boetseerlessen.
                    Daar kreeg ik nu meer tijd voor. De cursisten zijn erg enthousiast en maken prachtige werkstukken. Zelf
                    ben
                    ik ook nog veel bezig met boetseren. Soms loopt mijn hoofd over van ideeën. Een keuze maken uit al die
                    ideeën is soms lastig.</p>
                <p class="paragraph-text text-white">Begin 2000 heb ik samen met anderen het Warnsvelds Artistiek Quotum
                    (WAQ)
                    opgericht. Na daar een aantal jaren de kar te hebben getrokken, was het tijd voor een ander. Het laatste
                    weekend van september was altijd de kunstroute. Helaas is WAQ gestopt. Ik probeer nu zelf een expositie
                    in
                    mijn huis in te richten rond die tijd.</p>
            </div>
            <div class="px-lg-4 py-4 mx-lg-4 leftr col-12 col-lg-3">
                <img src="@/assets/portret/portret.jpeg" class="w-100 float-end text-end img">
            </div>
        </div>

        <hr/>

        <div class="px-4 px-lg-0 pe-lg-5">
            <div class="w-100 row row-cols-1 row-cols-lg-2 px-lg-4 py-4 mx-lg-4">
                <div class="col p-0">
                    <img src="@/assets/portret/vorm1.jpeg" class="w-100 img fitment">
                </div>
                <div class="col p-0">
                    <img src="@/assets/portret/vorm2.jpeg" class="w-100 img fitment">
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import FlexBanner from '@/components/FlexBannerComponent.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [{
                url: "/",
                title: "Home",
            }, {
                url: "/over-mij",
                title: "Over mij",
            }]
        }
    }
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

.fitment {
    max-height: 512px;
    object-fit: cover;
    object-position: 0 40%;
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}</style>