<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="p-lg-4 py-4 ms-lg-4 left">
            <div class="row mt-4">
                <div class="col-md-6 col-lg-3">
                    <h2 class="border-0 d-none d-lg-block">Menu</h2>
                    <AdminNav/>
                </div>
                <div class="px-lg-5 col-lg-9">
                    <h1>Nieuws beheren</h1>
                    <p class="paragraph-text text-white">Hier vind je alle nieuwsartikelen. Deze kun je hier toevoegen, aanpassen en verwijderen.</p>

                    <h2 class="border-0 mt-5">Nieuw nieuwsartikel toevoegen</h2>
                    <div @click="addNewSculpture" class="btn btn-gold fw-bold"><i class="fa fa-plus me-1"></i>Nieuw nieuwsartikel toevoegen</div>

                    <h2 class="border-0 mt-5">Alle nieuwsartikelen</h2>
                    <template v-if="news.length">
                        <table class="text-white paragraph-text">
                            <thead>
                                <tr>
                                    <th class="px-4">Titel</th>
                                    <th class="px-4">Geplaatst op</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="article in news" :key="article.id" class="border-bottom border-subtle w-100" role="button" @click="() => editNews(article)">
                                    <td class="py-2 px-4">{{ article.title }}</td>
                                    <td class="py-2 px-4 w-100">{{ article.publication_date }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="w-100 bg-warning p-3 rounded-2 fw-bold">Er zijn geen categorieën gevonden</div>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <AdminModal :purpose="currentModal?.purpose" :title="currentModal?.title" :content="currentModal?.content" :openModal="openModal" @closeModal="closeModal"  :id="currentModal.id" :publication_date="currentModal.publication_date" :pictures="currentModal.pictures"/>
</template>

<script type="text/javascript">
// import $ from 'jquery';

import FlexBanner from '@/components/FlexBannerComponent.vue';
import AdminNav from '@/components/AdminNavComponent.vue';
import AdminModal from '@/components/AdminModalComponent.vue';
export default {
    name: 'admin-dashboard',
    components: {
        FlexBanner,
        AdminNav,
        AdminModal,
    },
    data() {
        return {
            categories: [],
            openModal: false,
            currentModal: 'news',
            news: [],
        }
    },
    async mounted() {
        await this.fetchCategories();
    },
    methods: {
        async fetchCategories() {
                await fetch('https://vorm-vrij.nl/scripting/get_news.php').then((response) => {
                return response.json();
            }).then((res) => {
                this.news = res;
            }).catch((err) => {
                console.log(err);
            });
        },
        addNewSculpture() {
            this.openModal = true;
            this.currentModal = {
                title: "Nieuw artikel",
                purpose: "news",
            }
        },
        editNews(news) {
            this.openModal = true;
            this.currentModal = {
                title: news.title,
                content: news.content,
                publication_date: news.publication_date,
                pictures: news.pictures,
                id: news.id,
                purpose: 'edit_news',
            }
        },
        closeModal() {
            this.openModal = false;
        },
    },
}
</script>

<style scoped>
.border-subtle {
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>