<template>
    <div :class="['position-fixed overlay p-3 text-center', openModal ? 'pe-all' : 'pe-none hidden']" id="overlay">
        <div :class="['modal-image mt-4 mx-auto text-white text-start rounded-0 position-relative', openModal ? '' : 'hide']">
            <i class="fa fa-times position-absolute" @click="closeModal"></i>
            <div class="p-4">
                <h2 class="border-0 h1 display-5 m-0">{{ title }}</h2>
            </div>
            <hr class="m-0" />
            <div class="p-4" v-if="description?.length || (width > 0 && height > 0)">
                <p class="paragraph-text m-0" v-if="description?.length">{{ description }}</p>
                <p class="paragraph-text m-0 fw-bold" v-if="width > 0 && height > 0">{{ width }} x {{ height }} cm</p>
            </div>
            <hr class="m-0" v-if="description?.length || (width > 0 && height > 0)" />
            <div class="p-4 max-size">
                <img :src="direct_image_url !== 'na' ? `/upload/foto/${direct_image_url}` : '/upload/foto/nog_geen.jpg'" class="w-100 thumbnail">
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';

export default {
    name: 'image-modal',
    props: ['title', 'description', 'direct_image_url', 'width', 'height', 'openModal'],
    data() {
        return { }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        }
    },
    mounted() {
        $("#overlay").click((e) => {
            if($(e.target).attr('id') === 'overlay')
                this.$emit('closeModal');
        });
    }
}
</script>

<style scoped>
.overlay {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1500;
    transition: background-color 500ms ease;
}

.hide {
    opacity: 0;
}

.fa-times {
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
    transition: transform 400ms ease;
}

.fa-times:hover {
    transform: rotateZ(90deg) scale(125%);
}

.max-size {
    max-height: 62vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.max-size::-webkit-scrollbar {
    width: 0;
}

.hidden {
    background-color: transparent;
}

.modal-image {
    width: 100%;
    max-width: 640px;
    background-color: var(--bg-nella);
    box-shadow: 1px 2px 39px #000;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: opacity 400ms ease;
}
</style>