<template>
    <div class="w-100 position-relative container-banner overflow-hidden ">
        <div :class="['w-100 banner-2', backdrop]" id="banner2"></div>
        <div :class="['w-100 banner', image]" id="banner"></div>
        <img src="@/assets/beelden/foreground.png" class="banner-3 opacity-0" id="bannerMouse">
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'flex-banner',
    components: {},
    props: ['image', 'backdrop'],
    data() {
        return {}
    },
    methods: {
        scrollBanner(banner) {
            const scrollY = window.scrollY;
            banner.style.backgroundPositionY = `${50 - scrollY / 96}%`;
        }
    },
    mounted() {
        let banner = document.getElementById("banner");
        let banner2 = document.getElementById("banner2");
        let bannerMouse = document.getElementById("bannerMouse");

        const scrollY = window.scrollY;
        let resistance_low = screen.width > 996 ? 106 : 212;
        let resistance_high = screen.width > 996 ? 12 : 2;
        let resistance_mouse = screen.width > 996 ? 3 : 6;

        banner.style.backgroundPositionY = `${50 - scrollY / resistance_low}%`;
        banner2.style.backgroundPositionY = `${50 - scrollY / resistance_high}%`;
        bannerMouse.style.transform = `translateY(${200 - scrollY / resistance_mouse}px)`;

        window.addEventListener("scroll", function() {
            let resistance_low = screen.width > 996 ? 106 : 212;
            let resistance_high = screen.width > 996 ? 12 : 2;

            const scrollY = window.scrollY;
            banner.style.backgroundPositionY = `${50 - scrollY / resistance_low}%`;
            banner2.style.backgroundPositionY = `${50 - scrollY / resistance_high}%`;
            bannerMouse.style.transform = `translateY(${200-scrollY / resistance_mouse}px)`;
        });
    }
}
</script>

<style scoped>
.banner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    background-image: url("@/assets/beelden/banner_afbeelding_transparant.png");
    background-size: cover;
    background-position: 50%;
    z-index: 1;
    /* mix-blend-mode:soft-light; */
}

.banner-houses {
    background-image: url("@/assets/beelden/hangende_huisjes.png") !important;
}

.banner-gray {
    background-image: url("@/assets/beelden/background_grijs.png") !important;
}

.banner-2 {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    background-image: url("@/assets/beelden/bg-drop.jpg");
    background-size: cover;
    background-position: 50%;
}

.banner-3 {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.container-banner {
    height: 300px;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3) inset;
}

@media screen and (max-width: 991px) {
    .container-banner {
        height: 150px;
    }
}

.banner-image {
    width: 100%;
    object-fit: cover;
    object-position: 0%;
}
</style>
