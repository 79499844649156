<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <img src="@/assets/beelden/fruit_bokeh.png" class="one-image">
        <div class="px-lg-4 py-4 mx-lg-4 leftr">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />
            <h1 class="mt-4">Eigen werk</h1>
            <p class="text-white paragraph-text">
                Welkom bij Eigen werk. Op deze pagina laat ik je graag de werkstukken zien waar ik aan heb gewerkt. Om dit iets makkelijker te maken, zijn de verschillende soorten projecten die ik heb gedaan opgedeeld in categorieën.
            </p>
            <div class="mb-5"></div>
            <h2 class="mt-5 text-gold">Categorieën</h2>
            <div class="row" style="gap: 1em">
                <div class="btn btn-gold fw-bold col nav-button-category" v-for="category in categories" :key="category.id" :id="'goto-' + category.name">{{ category.name }}</div>
            </div>
            <!-- Collage van keramieke beelden -->
            <template v-for="category in categories" :key="category.id">
                <h2 class="mt-5" :id="category.name">{{ category.name }} ({{ category.sculptures.length }})</h2>
                <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                    <div class="col" v-for="item in category.sculptures" :key="item.id">
                        <img :src="item.direct_image_url !== 'na' ? `upload/foto/${item.direct_image_url}` : 'upload/foto/nog_geen.jpg'" class="w-100 image border-subtle" :alt="item.name" :title="item.name" @click="() => expandPhoto(item)">
                        <div class="p-0 py-2 py-sm-3 p-sm-3">
                            <p class="text-white fw-bold m-0">{{ item.name }}</p>
                            <p class="text-white m-0">{{ item.width }} x {{ item.height }} cm</p>
                        </div>
                    </div>
                </div>
                <hr class="my-3 opacity-0"/>
            </template>

            <!-- Collage van de rest -->
            <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                <div class="col" v-for="item in ceramicItems" :key="item.id">
                    <img :src="item.direct_image_url !== 'na' ? `upload/foto/${item.direct_image_url}` : 'upload/foto/nog_geen.jpg'" class="w-100 image border-subtle" :alt="item.name" :title="item.name" @click="() => expandPhoto(item)">
                    <div class="p-0 py-2 py-sm-3 p-sm-3">
                        <p class="text-white fw-bold m-0">{{ item.name }}</p>
                        <p class="text-white m-0">{{ item.width }} x {{ item.height }} cm</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ImageModal :title="currentItem?.name" :description="currentItem?.description" :width="currentItem?.width" :height="currentItem?.height" :direct_image_url="currentItem?.direct_image_url" :openModal="openModal" @closeModal="closeModal" />
</template>

<script type="text/javascript">
/*eslint-disable*/
import FlexBanner from "@/components/FlexBannerComponent.vue";
import Collage from "@/components/CollageComponent.vue";
import Breadcrumbs from "@/components/BreadcrumbsComponent.vue";
import ImageModal from "@/components/ImageModalComponent.vue";

import $ from 'jquery';

export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Collage,
        Breadcrumbs,
        ImageModal,
    },
    data() {
        return {
            breadcrumbs: [{
                url: "/",
                title: "Home",
            }, {
                url: "/eigen-werk",
                title: "Eigen werk",
            }],
            ceramicItems: [],
            outsideItems: [],
            currentItem: undefined,
            openModal: false,
            categories: [],
        }
    },
    methods: {
        expandPhoto(item) {
            this.currentItem = item;
            this.openModal = true;
        },
        closeModal() {
            this.openModal = false;
        },
        async fetchCategories() {
                await fetch('https://vorm-vrij.nl/scripting/get_categories.php').then((response) => {
                return response.json();
            }).then((res) => {
                this.categories = res;
                this.categories.forEach((element) => {
                    element.sculptures = [];
                });
                this.fetchSculptures();
            }).catch((err) => {
                console.log(err);
            })
        },
        async fetchSculptures() {
                await fetch('https://vorm-vrij.nl/scripting/get_sculptures.php').then((response) => {
                return response.json();
            }).then((res) => {
                let items = res;
                items.forEach((element) => {
                    if(element.category_id) {
                        this.categories.forEach((category) => {
                            if(category.id === element.category_id) {
                                category.sculptures.push(element);
                            }
                        });
                    } else {
                        this.ceramicItems.push(element);
                    }
                });
                $(".nav-button-category").on('click', (e) => {
                    let id = $(e.target).attr('id').split("-")[1];

                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(`#${id}`).offset().top - 150
                    }, 1000);
                });
            }).catch((err) => {
                console.log(err);
            });
        }
    },
    async mounted() {
        await this.fetchCategories();
        document.title = "Eigen Werk | Vorm-vrij";
    },
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

.d-flex {
    gap: 1em;
}

.image {
    transition: transform 300ms ease-in-out;
    cursor: pointer;
    animation: appear 900ms forwards;
}
.image:hover {
    transform: scale(105%);
}

@keyframes appear {
    0% {
        opacity: 0;
    }    

    100% {
        opacity: 1;
    }
}

.border-subtle {
    border: 1px solid rgba(255, 255, 255, 0.15);
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}
</style>