<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <img src="@/assets/beelden/fruit_bokeh.png" class="one-image">
        <div class="px-lg-4 py-4 mx-lg-4 leftr">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />
            <h1 class="mt-4">Gastenboek</h1>
            <p class="text-white paragraph-text">
                Welkom bij het gastenboek. Dit is de plek waar alle reacties op mijn website te vinden zijn.
            </p>
            <h2 class="mt-5 border-0">Laat een reactie achter</h2>
            <div class="p-4 border border-light rounded-1 subtle-border">
                <form method="post" id="guestbookForm">
                    <div class="row form-group">
                        <div class="col-12 col-lg-1">
                            <label class="text-white fw-bold h5">Naam</label>
                        </div>
                        <div class="col-12 col-lg-4">
                            <input type="text" class="form-control rounded-1" name="name" placeholder="Je naam" required>
                        </div>
                    </div>

                    <!-- Bericht -->
                    <div class="row row-cols-1 form-group mt-3">
                        <div class="col-12 mb-3">
                            <label class="text-white fw-bold h5">Bericht</label>
                        </div>
                        <div class="col-12 col-lg-5">
                            <textarea rows="5" class="form-control rounded-1" name="message" placeholder="Schrijf hier je bericht" required></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button type="submit" id="submitGuestbookButton" href="#" class="btn btn-secondary btn-gold border-0 mt-3 rounded-1"><i
                                    class="fa fa-envelope me-1"></i> Verzenden</button>
                        </div>
                    </div>
                </form>
            </div>
            <h2 class="mt-5">Reacties</h2>
            <div class="row">
                <div class="col-12 col-lg-3">
                    <select class="form-select" @change="fetchGuestbook">
                        <option selected value="">-- Sorteren --</option>
                        <option value="nieuwste-eerst">Nieuwste eerst</option>
                        <option value="oudste-eerst">Oudste eerst</option>
                        <option value="a-z">Schrijver A-Z</option>
                        <option value="z-a">Schrijver Z-A</option>
                    </select>
                </div>
            </div>
            <template v-if="guestbook_entries.length > 0">
                <template v-for="entry in guestbook_entries" :key="entry.id">
                    <div class="text-white mt-5 entry">
                        <div class="mb-1">
                            <span class="fw-bold text-gold">{{ entry.name && entry.name.length ? entry.name : 'Iemand' }}</span>&nbsp;schreef op&nbsp;<span class="fw-bold text-gold">{{ formatDate(entry.date_unix) }}&nbsp;</span>
                        </div>
                        <div>
                            {{ entry.message }}
                        </div>
                        <hr />
                    </div>
                </template>
            </template>

            <template v-else>
                <div class="text-white mt-5">
                    Er staat nog niets in het gastenboek! Wees de eerste die een reactie plaatst.
                </div>
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
import FlexBanner from "@/components/FlexBannerComponent.vue";
import Breadcrumbs from "@/components/BreadcrumbsComponent.vue";
import $ from 'jquery';

export default {
    name: 'home-page',
    components: {
        FlexBanner,
        Breadcrumbs,
    },
    data() {
        return {
            breadcrumbs: [{
                url: "/",
                title: "Home",
            }, {
                url: "/gastenboek",
                title: "Gastenboek",
            }],
            guestbook_entries: [],
        }
    },
    methods: {
        formatDate(date, showTime = false) {
            let day, month, year, hour, minutes;
            date = new Date(date * 1000);
            day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
            month = (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
            year = date.getFullYear();
            hour = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours();
            minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes();

            return [day, month, year].join('-') + (showTime ? ' om ' + [hour, minutes].join(":") : '');
        },
        async fetchGuestbook(sort = "") {
                await fetch('https://vorm-vrij.nl/scripting/guestbook/get_guestbook.php' + (sort?.srcElement?.value ? `?sorteer=${sort.srcElement.value}` : `?sorteer=nieuwste-eerst`)).then((response) => {
                return response.json();
            }).then((res) => {
                this.guestbook_entries = res;
            }).catch((err) => {
                console.log(err);
            });
        }
    },
    async mounted() {
        this.fetchGuestbook();

        $("#guestbookForm").on("submit", function(e) { 
            e.preventDefault();
            var $inputs = $("#guestbookForm :input");
            var values = {};
            $inputs.each(function() {
                values[this.name] = $(this).val();
                $(this).val("");
            });

            $("#submitGuestbookButton").animate({
                transform: "translateX(4000px)"
            });
            
            $.ajax({
                url: `https://vorm-vrij.nl/scripting/guestbook/add_guestbook.php?name=${values['name']}&message=${values['message']}`,
                method: 'POST',
                data: JSON.stringify(values),
                dataType: 'application/json',
                success: function() {

                }
            });
        });
        document.title = "Gastenboek | Vorm-vrij";
    }
}
</script>

<style scoped>
.paragraph {
    background-color: var(--bg-paragraph);
    overflow: hidden;
}

@keyframes fly-off {
    0% {
        transform: translateX(-9px);
    }

    10% {
        transform: translateX(-9px);
    }

    64% {
        transform: translateX(4000px);
    }
}

.subtle-border {
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.h5 {
    transform: translateY(0.33em);
}

.entry {
    animation: appear 400ms ease-in-out;
}

@keyframes appear {
    0% {
        transform: translateX(-2em);
        opacity: 0;
    }

    100% {
        transform: 0;
        opacity: 1;
    }
}

.one-image {
    position: absolute;
    left: 0;
    top: -25%;
    transform: rotate(10deg);
    opacity: 0.01;
    width: 100%;
    pointer-events: none;
}

.fruit-banner {
    object-fit: cover;
    height: 300px;
}
</style>