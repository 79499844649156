<template>
    <div class="d-block d-xl-flex preview text-start">
        <div class="w-100 px-lg-4 inset">
            <div class="mx-lg-4 p-4 p-lg-5 p-start">
                <div class="w-100">
                    <h3 class="mb-4">Voorproefje...</h3>
                    <p class="paragraph-text text-white">
                        Voor alle beelden die ik heb gemaakt, zie de <router-link to="/eigen-werk">Eigen werk pagina</router-link>!
                    </p>
                    <div class="preview-carousel">
                        <template v-for="(item, index) in previewItems" :key="item.id">
                            <img :id="'image-' + item.id" :src="item.direct_image_url !== 'na' ? `upload/foto/${item.direct_image_url}` : 'upload/foto/nog_geen.jpg'" :class="['w-100 h-100 position-absolute preview-image', currentImageIndex === index ? '' : 'disappear']">
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contact -->
        <div class="w-100 side-news">
            <div class="p-4 p-lg-5">
                <div class="d-block d-sm-flex w-100 position-relative text-center text-md-start">
                    <img src="@/assets/portret/portret.jpeg" class="w-100 portrait mx-auto">
                    <div class="w-100 ms-md-5">
                        <h3 class="mt-4 mt-sm-0">Nella Francke</h3>
                        <hr class="d-block d-sm-none">
                        <div class="row row-cols-2 my-3 text-start">
                            <div class="col-3 text-white fw-bold paragraph-text">
                                Adres:
                            </div>
                            <div class="col-8 text-white paragraph-text">
                                't Spiker 103, 7231 JP Warnsveld
                            </div>
                        </div>

                        <div class="row row-cols-2 my-3 text-start">
                            <div class="col-3 text-white fw-bold paragraph-text">
                                Telefoon:
                            </div>
                            <div class="col-8 text-white paragraph-text">
                                06 16 80 06 69
                            </div>
                        </div>

                        <div class="row row-cols-2 my-3 text-start">
                            <div class="col-3 text-white fw-bold paragraph-text">
                                E-mail:
                            </div>
                            <div class="col-8 text-white paragraph-text">
                                franckenella@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="m-0"/>

            <div class="p-4 p-lg-5">
                <h1 class="border-0">Nieuws</h1>
                <template v-for="article in articles" :key="article.title">
                    <h4 class="text-white m-0 mb-1">{{ article.title }}</h4>
                    <div class="text-white bg-dark my-2 p-2 paragraph-text rounded-2 d-inline-block px-3">Geplaatst: {{ article.publication_date }}</div>
                    <p class="text-white paragraph-text">{{ article.content }}</p>
                    <router-link to="/nieuws">Lees verder...</router-link>
                    <hr/>
                </template>
                <router-link to="/nieuws" class="btn btn-gold">Klik hier voor meer nieuws</router-link>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
// import { Carousel, Slide } from 'vue-carousel';
export default {
    components: {
        // Carousel,
        // Slide,
    },
    data() {
        return {
            previewItems: [],
            currentImageIndex: 0,
        }
    },
    async mounted() {
        await // Haal ook nieuws op
        fetch('https://vorm-vrij.nl/scripting/get_news.php').then((response) => {
            return response.json();
        }).then((response) => {
            this.articles = response.splice(0, 3);
        });
        
        await fetch('https://vorm-vrij.nl/scripting/get_sculptures.php').then((response) => {
            return response.json();
        }).then((res) => {
            this.previewItems = res.splice(0, 5);

            setInterval(() => {
                this.currentImageIndex++;
                if(this.currentImageIndex > this.previewItems.length - 1)
                    this.currentImageIndex = 0;
            }, 4000);
        }).catch((err) => {
            console.log(err);
        });
    }
}
</script>

<style scoped>
.preview {
    background-color: var(--bg-nav);
}

.disappear {
    opacity: 0;
    transition: opacity 900ms ease-in-out, transform 900ms ease-in-out;
}

.preview-image {
    object-fit: cover;
    transition: opacity 900ms ease-in-out;
}

.preview-carousel {
    height: 680px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    position: relative;
    overflow: hidden;
}

.inset {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4) inset;
}

.portrait {
    max-width: 260px;
}

.image-collage {
    width: 100%;
}
</style>