
import FlexBannerComponentVue from '@/components/FlexBannerComponent.vue';
<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="p-lg-4 py-4 ms-lg-4 left">
            <div class="row mt-4">
                <div class="col-lg-3">
                    <h2 class="border-0 d-none d-lg-block">Menu</h2>
                    <AdminNav/>
                </div>
                <div class="px-lg-5 col-lg-9">
                    <h1>Administratie</h1>
                    <p class="paragraph-text text-white">Vanuit het administratiepaneel kun je je website beheren. Dit is het <strong>dashboard</strong> waar je gelijk wat gegevens over je website kunt bekijken.</p>
                    <hr />
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import FlexBanner from '@/components/FlexBannerComponent.vue';
import AdminNav from '@/components/AdminNavComponent.vue';
export default {
    name: 'admin-dashboard',
    components: {
        FlexBanner,
        AdminNav,
    }
}
</script>

<style scoped>
</style>