<template>
    <div class="w-100 p-5 text-white bg-dark">
        <div class="w-md-75 mx-auto row row-cols-1 row-cols-lg-3 justify-content-center">
            <div class="col text-start paragraph-text mb-4 mx-auto">
                <b class="mb-2">Sitemap</b><br/>
                <router-link class="mb-4" to="/">Home</router-link><br/>
                <router-link class="mb-4" to="/over-mij">Over mij</router-link><br/>
                <router-link class="mb-4" to="/eigen-werk">Eigen werk</router-link><br/>
                <router-link class="mb-4" to="/werkwijze">Werkwijze</router-link><br/>
                <router-link class="mb-4" to="/cursistenwerk">Curistenwerk</router-link><br/>
                <router-link class="mb-4" to="/nieuws">Nieuws</router-link><br/>
                <router-link class="mb-4" to="/gastenboek">Gastenboek</router-link><br/>
                <router-link class="mb-4" to="/contact">Contact</router-link><br/>
            </div>
            <div class="col text-start paragraph-text mb-4 mx-auto">
                <b class="mb-2">Over mij</b><br/>
                <a href="/files/CV Nella Francke 2022.pdf" download>Mijn CV</a><br/>
            </div>
            <div class="col text-start paragraph-text mb-4 mx-auto">
                <b class="mb-2">Extra</b><br/>
                <router-link class="mb-4" to="/">Algemene voorwaarden</router-link><br/>
            </div>
        </div>
        <p class="paragraph-text m-0">
            <small>
                &copy;&nbsp;{{ copyrightYear }} | Vorm-vrij.nl | Nella Francke - Gemaakt door Noah Pauw
            </small>
        </p>
    </div>
</template>

<script type="text/javascript">
export default {
    name: 'footer-component',
    data() {
        return {
            copyrightYear: 2024
        }
    },
    mounted() {
        let date = new Date();
        this.copyrightYear = date.getFullYear();
    }
}
</script>

<style scoped>
</style>