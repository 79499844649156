<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="p-lg-4 py-4 ms-lg-4 left">
            <div class="row mt-4">
                <div class="col-md-6 col-lg-3">
                    <h2 class="border-0 d-none d-lg-block">Menu</h2>
                    <AdminNav/>
                </div>
                <div class="px-lg-5 col-lg-9">
                    <h1>Categorieën beheren</h1>
                    <p class="paragraph-text text-white">Hier kun je categorieën bekijken en beheren.</p>

                    <h2 class="border-0 mt-5">Nieuwe categorie toevoegen</h2>
                    <div @click="addNewSculpture" class="btn btn-gold fw-bold"><i class="fa fa-plus me-1"></i>Nieuwe categorie toevoegen</div>

                    <h2 class="border-0 mt-5">Alle categorieën</h2>
                    <template v-if="categories.length">
                        <table class="text-white paragraph-text">
                            <thead>
                                <tr>
                                    <th class="px-4">Prioriteit</th>
                                    <th class="px-4">Naam</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="category in categories" :key="category.id" class="border-bottom border-subtle w-100" role="button" @click="() => editExistingCategory(s)">
                                    <td class="py-2 px-4">{{ parseInt(category.priority) + 1 }}</td>
                                    <td class="py-2 px-4 w-100">{{ category.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="w-100 bg-warning p-3 rounded-2 fw-bold">Er zijn geen categorieën gevonden</div>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <AdminModal :purpose="currentModal?.purpose" :title="currentModal?.title" :description="currentModal?.description" :width="currentModal?.width" :height="currentModal?.height" :direct_image_url="currentModal?.direct_image_url" :openModal="openModal" @closeModal="closeModal" :category_id="currentModal?.category_id" :is_cursistenwerk="currentModal?.is_cursistenwerk" :id="currentModal?.id" />
</template>

<script type="text/javascript">
// import $ from 'jquery';

import FlexBanner from '@/components/FlexBannerComponent.vue';
import AdminNav from '@/components/AdminNavComponent.vue';
import AdminModal from '@/components/AdminModalComponent.vue';
export default {
    name: 'admin-dashboard',
    components: {
        FlexBanner,
        AdminNav,
        AdminModal,
    },
    data() {
        return {
            categories: [],
            openModal: false,
            currentModal: undefined,
        }
    },
    async mounted() {
        await this.fetchCategories();
    },
    methods: {
        async fetchCategories() {
            await fetch('https://vorm-vrij.nl/scripting/get_categories.php').then((response) => {
                return response.json();
            }).then((res) => {
                this.categories = res;
            }).catch((err) => {
                console.log(err);
            });
        },
        addNewSculpture() {
            this.openModal = true;
            this.currentModal = {
                title: "Nieuw beeld",
                purpose: "add_sculpture",
            }
        },
        editExistingCategory(sculpture) {
            this.openModal = true;
            this.currentModal = {
                title: "Categorie bewerken",
                purpose: "edit_category",
            }
            this.currentModal = sculpture;
            this.currentModal.purpose = "edit_category";
            this.currentModal.title = sculpture.name;
        },
        closeModal() {
            this.openModal = false;
        },
    },
}
</script>

<style scoped>
.border-subtle {
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>