<template>
    <div class="mb-5 d-block">
        <template v-for="route in routes" :key="route.id">
            <router-link :to="route.to" v-if="!route.logout" :class="['me-2 text-start mb-3 btn text-black fw-normal btn-text', route.logout ? 'btn-danger' : (currentRouteName === route.to ? 'btn-light' : 'btn-gold') ]"><i :class="['fa me-1', route.icon]"></i> {{ route.text }}</router-link>
            <div class="btn btn-danger me-2 text-start mb-3 text-black, fw-normal, btn-text" v-if="route.logout" id="uitloggen"><i :class="['fa me-1', route.icon]"></i> {{ route.text }}</div>
        </template>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';

export default {
    name: 'admin-nav',
    data() {
        return {
            routes: [{
                to: '/admin',
                text: 'Dashboard',
                icon: 'fa-pie-chart',
                logout: false,
            }, {
                to: '/admin/beeld-beheer',
                text: 'Beelden beheren',
                icon: 'fa-museum',
                logout: false,
            }, {
                to: '/admin/categorieen',
                text: 'Categorieën beheren',
                icon: 'fa-bookmark',
                logout: false,
            }, {
                to: '/admin/nieuws',
                text: 'Nieuws beheren',
                icon: 'fa-newspaper',
                logout: false,
            }, {
                to: '/',
                text: 'Gastenboek beheren',
                icon: 'fa-book-open',
                logout: false,
            }, {
                to: '/',
                text: 'Tekst op website aanpassen',
                icon: 'fa-book',
                logout: false,
            }, {
                to: '/',
                text: 'Uitloggen',
                icon: 'fa-arrow-right',
                logout: true,
            }
        ]
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.fullPath;
        }
    },
    mounted() {
        $("#uitloggen").on('click', () => {
            localStorage.removeItem('userToken');
            this.$router.push('/admin/login');
        });
    }
}
</script>